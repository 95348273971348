/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    li: "li",
    a: "a",
    section: "section",
    div: "div",
    img: "img",
    canvas: "canvas",
    h3: "h3",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, Button, CallToAction} = _components;
  if (!Button) _missingMdxReference("Button", true);
  if (!CallToAction) _missingMdxReference("CallToAction", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, null, React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#deforestation-routes"
  }, "Deforestation Routes")), React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#smart-agriculture"
  }, "Smart Agriculture"))), React.createElement(_components.section, {
    className: "uk-section uk-position-relative uk-section-image-content uk-background-light uk-overflow-hidden  ",
    id: "deforestation-routes"
  }, React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-1"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-2"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-3"
  }), React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.div, {
    className: "uk-width-image-column  uk-image-float-left uk-margin-large-bottom@m uk-margin-medium-bottom"
  }, React.createElement(_components.div, {
    className: "uk-background-primary uk-overflow-hidden uk-position-relative  uk-border-rounded"
  }, React.createElement(_components.div, {
    className: "uk-width-1-1 uk-height-1-1 uk-background-sixth uk-position-absolute"
  }), React.createElement(_components.div, {
    className: "uk-cover-container"
  }, React.createElement(_components.img, {
    src: "/assets/images/our-commitment/image-content-1.jpg",
    alt: "",
    className: " uk-utility-object-fit-cover"
  }), React.createElement(_components.canvas, {
    width: "450",
    height: "600"
  })))), React.createElement(_components.h3, {
    className: "uk-style-secondary uk-text-ls-reduce uk-text-5"
  }, "Causes linked to the economic and social reasons behind deforestation"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Capacity building to increase the number of forest\nprotection projects are crucial in the coming years to meet our biodiversity and emissions targets. PACTs significantly\ndrop the startup cost of designing new projects, and provide a digital framework for evaluating biodiversity, local\nlivelihoods and other cobenefits. A key goal is to enable diverse smallholders to participate in carbon finance to\nincrementally and credibly grow capacity.")), React.createElement(Button, {
    url: "#",
    style: "tertiary",
    highlight: "dark"
  }, "interested in getting involved?"))))), "\n", React.createElement(_components.section, {
    className: "uk-section uk-position-relative uk-section-image-content uk-background-dark uk-light uk-position-z-index uk-margin-large-top@m ",
    id: "smart-agriculture"
  }, React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.div, {
    className: "uk-width-image-column  uk-image-float-right uk-margin-large-bottom@m uk-margin-medium-bottom"
  }, React.createElement(_components.div, {
    className: "uk-background-primary uk-overflow-hidden uk-position-relative  uk-border-rounded"
  }, React.createElement(_components.div, {
    className: "uk-width-1-1 uk-height-1-1 uk-background-sixth uk-position-absolute"
  }), React.createElement(_components.div, {
    className: "uk-cover-container"
  }, React.createElement(_components.img, {
    src: "/assets/images/our-commitment/image-content-2.jpg",
    alt: "",
    className: " uk-utility-object-fit-cover"
  }), React.createElement(_components.canvas, {
    width: "450",
    height: "600"
  })))), React.createElement("img", {
    src: "/assets/images/svg_icons/cambridge-university-logo.svg",
    className: "uk-margin-small-top uk-margin-remove-top@m uk-margin-small-bottom"
  }), React.createElement(_components.h3, {
    className: "uk-style-secondary uk-text-ls-reduce uk-text-5"
  }, "Climate-Smart Agriculture"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Every PACT is associated with an intervention project which aims to avoid or sequester emissions. In the case of avoided tropical deforestation, this might be:")))))), "\n", React.createElement(_components.section, {
    className: "uk-section uk-section-cta uk-background-dark uk-position-relative"
  }, React.createElement(CallToAction)));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
